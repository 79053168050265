@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  /* background-color: rgb(0, 30, 60);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
}

.circle {
  /* width: 220px;
  height: 220px;
  position: relative; */
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid rgb(228 228 231);
}

.scrolled {
  background-color: rgba(79, 70, 229, 0.05) !important;
  backdrop-filter: blur(6px);
  transition: background-color 300ms linear;
}

.circle .border {
  /* content: ''; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  border-radius: 110%;
  border: 2px dotted rgba(255, 255, 255, 0.8);
  -webkit-animation: rotating 5s alternate infinite;
  -moz-animation: rotating 5s alternate infinite;
  -ms-animation: rotating 5s alternate infinite;
  -o-animation: rotating 5s alternate infinite;
  animation: rotating 5s alternate infinite;
}
.breath {
  animation: breath 1s alternate infinite;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes breath {
  0% {
    /* opacity: 0.8; */
    transform: scale(1);
  }

  100% {
    /* opacity: 1; */
    transform: scale(1.1);
    transform: translateY(-15%);
  }
}
